
import { computed, defineComponent, reactive, toRefs, watch } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const isMobile = computed(() => store.state.app.device === 1)
    const dataMap = reactive({
      image: require('@/assets/images/game_type/web_game.jpg'),
      slotName: '',
      hunterName: '',
      chessName: '',
      arcadeName: ''
    })
    const methods = reactive({
      onMouseenter(value: string, e: any) {
        dataMap.image = require(`@/assets/images/game_type/web_${value}.jpg`)
        if (e.target.children[0]) e.target.children[0].style = 'filter: brightness(1)'
      },
      onMouseleave(e: any) {
        if (e.target.children[0]) e.target.children[0].style = 'filter: brightness(.6)'
      },
      onClick(type: number) {
        router.push(`/list?type=${type}`)
      }
    })

    watch(() => store.state.app.config.resource, (n, o) => {
      if (n && n !== o) {
        dataMap.slotName = `${n}/bimg/${store.state.app.language}/game/t_slot.png`
        dataMap.hunterName = `${n}/bimg/${store.state.app.language}/game/t_hunter.png`
        dataMap.chessName = `${n}/bimg/${store.state.app.language}/game/t_chess.png`
        dataMap.arcadeName = `${n}/bimg/${store.state.app.language}/game/t_arcade.png`
      }
    }, { immediate: true })

    watch(() => store.state.app.language, (n, o) => {
      if (n && n !== o && store.state.app.config.resource) {
        dataMap.slotName = `${store.state.app.config.resource}/bimg/${n}/game/t_slot.png`
        dataMap.hunterName = `${store.state.app.config.resource}/bimg/${n}/game/t_hunter.png`
        dataMap.chessName = `${store.state.app.config.resource}/bimg/${n}/game/t_chess.png`
        dataMap.arcadeName = `${store.state.app.config.resource}/bimg/${n}/game/t_arcade.png`
      }
    }, { immediate: true })

    return {
      isMobile,
      ...toRefs(dataMap),
      ...toRefs(methods)
    }
  }
})
